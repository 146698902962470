@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

.image1{
    background-image: linear-gradient(105deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 55%, transparent 55%),url("./images/home-slider-photo1.png");
}

.image2{
    background-image: linear-gradient(105deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 55%, transparent 55%),url("./images/home-slider-photo2.png");
}

.image3{
    background-image: linear-gradient(105deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 55%, transparent 55%),url("./images/home-slider-photo3.png");
}

.product-main__image{
    background-image: linear-gradient(rgba(58, 61, 52, 0.8), rgba(58, 61, 52, 0.8), transparent),url("./images/products-main-photo.png");
}

.product-doors__image{
    background-image: linear-gradient(rgba(58, 61, 52, 0.8), rgba(58, 61, 52, 0.8), transparent),url("./images/products-doors.jpg");
}

.product-laminant__image{
    background-image: linear-gradient(rgba(58, 61, 52, 0.8), rgba(58, 61, 52, 0.8), transparent),url("./images/products-laminant.jpg");
}

.product-lamine__image{
    background-image: linear-gradient(rgba(58, 61, 52, 0.8), rgba(58, 61, 52, 0.8), transparent),url("./images/products-lamine.jpg");
}

.product-skirts__image{
    background-image: linear-gradient(rgba(58, 61, 52, 0.8), rgba(58, 61, 52, 0.8), transparent),url("./images/products-skirts.jpg");
}

.absolute-center__text{
    position: absolute;    
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
}

.active{
    color:#181916;
    text-decoration: underline;
}

.home-image{
    transition:all .5s ease-out;
}
.card-text{
    transition:all .5s ease-out;
}

.home-card:hover .home-image{
    filter:blur(2px) brightness(65%);
}

.home-card:hover .card-text{
    display:block;
}

.product-card-text{
    transition:all .5s ease-out;
}
.product-card:hover .product-card-text{
    transform:translate(-50%,-50%) scale(1.1);
}

.absoluteCenter{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
}

